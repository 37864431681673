@import 'app/scss/variables';
@import 'app/scss/mixins';

.h-account {
  &-section {
    overflow: hidden;
    @include breakpoint('mobile') {
      background: linear-gradient(126.59deg, #6171BF 6.68%, #18003F 71.3%);
    }
    @include breakpoint('tablet') {
      background: linear-gradient(126.59deg, #6171BF 6.68%, #18003F 71.3%);
    }
    @include breakpoint('desktop') {
      background: linear-gradient(126.59deg, #556AB4 6.68%, #18003F 71.3%);
    }
  }
  &-content {
    position: relative;

    @include breakpoint('mobile') {
      padding-top: 40px;
      height: 812px;
    }
    @include breakpoint('tablet') {
      padding-top: 40px;
      height: 952px;
    }
    @include breakpoint('desktop') {
      padding: 140px 0;
    }
    &__img {
      position: absolute;

      @include breakpoint('mobile') {
        width: 676px;
        bottom: -70px;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 18px;
      }
      @include breakpoint('tablet') {
        width: 785.5px;
        bottom: -96px;
        left: 50%;
        transform: translateX(-50%);
        margin-left: 10px;
      }
      @include breakpoint('desktop') {
        width: 1720px;
        top: -212px;
        left: 50%;
        margin-left: -573px;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}