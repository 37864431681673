@import 'app/scss/variables';
@import 'app/scss/mixins';

.o-hero {
  &-section {
    color: $color-main;
    position: relative;
    overflow: hidden;

    @include breakpoint('tablet') {
      padding-bottom: 100px;
      margin-bottom: -100px;
    }
  }
  &-content {
    @include breakpoint('mobile') {
      padding: 160px 0 332px;
    }
    @include breakpoint('tablet') {
      padding: 168px 0 452px;
    }
    @include breakpoint('desktop') {
      padding: 304px 0;
    }
  }
  &-title {
    position: relative;
    z-index: 1;
    @include breakpoint('mobile') {
     font: 24px/32px 'Mulish';
    }
    @include breakpoint('tablet-up') {
      font: 40px/48px 'Mulish';
      font-weight: 400;
      width: 440px;
    }
  }
  &-img {
    position: absolute;
    display: flex;
    align-items: flex-start;

    @include breakpoint('mobile') {
      left: -22px;
      bottom: -82px;
    }
    @include breakpoint('tablet') {
      left: 164px;
      bottom: -52px;
    }
    @include breakpoint('desktop') {
      top: -66px;
      left: 50%;
      margin-left: -25px;
    }

    img {
      height: auto;

      @include breakpoint('mobile') {
        width: 706px;
      }
      @include breakpoint('tablet') {
        width: 1114px;
      }
      @include breakpoint('desktop') {
        width: 1398px;
      }
    }
  }
}