@import 'app/scss/variables';
@import 'app/scss/mixins';

.cp {
  &-section {
    background: linear-gradient(113.79deg, #FFFFFF -26.2%, #EBEBEB 60.83%);
    display: flex;
    
    @include breakpoint('mobile') {
      padding-top: 96px;
    }
    @include breakpoint('tablet-up') {
      min-height: 100%;
      padding-top: 112px;
    }
  }
  &-layout {
    display: flex;
    flex-grow: 1;

    @include breakpoint('mobile') {
      flex-direction: column-reverse;
      padding-top: 16px;
    }
    @include breakpoint('tablet') {
      align-items: center;
    }
    @include breakpoint('tablet-up') {
      justify-content: space-between;
      padding-top: 40px;
      padding-bottom: 80px;
    }

    &__img {
      display: flex;
      align-items: center;
      position: relative;

      @include breakpoint('mobile') {
        justify-content: center;
        margin-top: 24px;
      }
      @include breakpoint('tablet-up') {
        margin-right: 20px;
      }
    }

    &__form {
      flex-grow: 1;
      @include breakpoint('tablet-up') {
        max-width: 405px;
        min-width: 332px;
      }
      @include breakpoint('desktop') {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &-title {
    h1 {
      display: inline-block;
      vertical-align: top;
      font: 40px/48px 'Mulish';
      font-weight: 700;
      
      @include textGradient {
        background: linear-gradient(90deg, #6B7ED1 0%, #6433CF 98.83%);
      }
    }
    p {
      margin-top: 16px;

      a {
        font-weight: 700;
        color: $color-main;
      }
    }
  }
  &-form {
    margin-top: auto;
    @include breakpoint('mobile') {
      padding-top: 28px;
    }
    @include breakpoint('tablet') {
      padding-top: 64px;
    }
    &-row {
      margin-top: 16px;

      &._btn {
        .btn {
          @include breakpoint('tablet-up') {
            width: 100%;
          }
        }
      }
    }
  }
  &-form-success {
    margin-top: auto;
    @include breakpoint('mobile') {
      padding-top: 40px;
    }
    @include breakpoint('tablet') {
      padding-top: 80px;
    }
    h2 {
      display: inline-block;
      vertical-align: top;
      font: 40px/48px 'Mulish';
      font-weight: 700;

      @include textGradient {
        background: linear-gradient(90deg, #6B7ED1 0%, #6433CF 98.83%);
      }
    }
    p {
      margin-top: 24px;
    }
    .btn {
      margin-top: 40px;
      @include breakpoint('tablet-up') {
        width: 100%;
      }
    }
  }
}