@import 'app/scss/variables';
@import 'app/scss/mixins';

.header {
  &-section {
    position: relative;
    z-index: 10;

    @include breakpoint('mobile') {
      padding: 24px 8px;
      margin-bottom: -96px;
    }
    @include breakpoint('tablet-up') {
      padding: 24px 0;
      margin-bottom: -112px;
    }
  }
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-logo {
    display: flex;
    align-items: flex-start;

    @include breakpoint('mobile') {
      position: relative;
      z-index: 4;
    }

    img {
      @include breakpoint('mobile') {
        width: 155px;
        height: 48px;
      }
      @include breakpoint('tablet-up') {
        width: 206px;
        height: 64px;
      }
    }
  }
  &-menu {
    display: flex;
    
    @include breakpoint('mobile') {
      padding: 40px 24px;
      width: 100%;
      position: relative;
    }
    @include breakpoint('tablet') {
      padding: 40px;
    }
    @include breakpoint('desktop-down') {
      flex-direction: column;
    }
    @include breakpoint('desktop') {
      align-items: center;
    }

    li {
      @include breakpoint('desktop-down') {
        &:not(:first-child) {
          margin-top: 8px;
        }
      }
      @include breakpoint('desktop') {
        padding-left: 40px;
      }
    }

    a:not(.btn-primary) {
      display: flex;
      align-items: center;
      color: $color-main;
      font-weight: 700;
      letter-spacing: 0.35px;

      svg {
        margin-right: 8px;
        path {
          fill: currentColor;
        }
      }

      ._white-theme & {
        @include breakpoint('desktop') {
          color: #fff;
        }
      }

      @include breakpoint('desktop-down') {
        font: 24px/32px 'Mulish';
        font-weight: 700;
        text-align: center;
        display: block;
        padding: 16px 0;
      }
    }
  }
  &-nav {
    @include breakpoint('mobile') {
      left: 0;
      background: $color-bg;
      transform: translateY(-100%);
      opacity: 0;
      @include transition(transform, opacity);
      overflow: hidden;

      ._menu-opened & {
        transform: translateY(0%);
        opacity: 1;
      }
    }
    @include breakpoint('tablet') {
      
      width: 334px;
      background: $color-bg;
      transform: translateX(100%);
      @include transition(transform);

      ._menu-opened & {
        transform: translateX(0%);
      }
    }
    @include breakpoint('desktop-down') {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      display: flex;
      align-items: center;
      overflow-y: auto;
    }
  }
  &-create-acc-btn {
    position: relative;
    z-index: 4;

    @include breakpoint('tablet') {
      margin: 0 40px 0 auto;
    }
  }
  &-toggle-menu-btn {
    display: flex;
    align-items: flex-start;
    background: none;
    border: none;
    width: 24px;
    height: 24px;
    @include transition(color);
    cursor: pointer;
    color: $color-main;
    position: relative;
    z-index: 4;

    ._white-theme &:not(._opened) {
      color: #fff;

      &:hover {
        color: #bcbcbe;
      }
    }

    &:hover {
      color: #bcbcbe;
    }

    svg {
      rect, path {
        fill: currentColor;
      }
    }
  }
  &-tablet-menu-overlay {
    @include breakpoint('tablet') {
      cursor: pointer;
      position: fixed;
      z-index: 2;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5);
      visibility: hidden;
      opacity: 0;
      @include transition(visibility, opacity);

      ._menu-opened & {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}