@import "src/app/scss/variables";
@import "src/app/scss/mixins";

.control-select {
  position: relative;

  ._error {
    .cs__control {
      &,
      &:hover {
        border-color: $color-wrong;
      }
    }
  }

  ._open-upwards .cs__menu {
    bottom: 100%;
    top: auto;
  }

  &__border {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 48px;
    background: #EBEBEB;
    border-radius: $radius;

    &:before {
      @include inputBorder();
    }
  }

  .cs {
    &-container {
      position: relative;
      padding: 1px;

      &._error {
        .control-select__border:before {
          background: $color-wrong;
          opacity: 1;
        }
      }
    }
    &__control {
      background: #fff;
      border: none;
      border-radius: $radius;
      min-height: 46px;
      cursor: pointer;
      @include transition(border-color);
      box-shadow: none;
      position: relative;
      z-index: 1;
      box-shadow: 0px 15px 51px -19px rgba(109, 109, 109, 0.21);

      &--menu-is-open {
        & ~ .control-select__border:before {
          opacity: 1;
        }
        .cs__indicator svg {
          transform: rotate(180deg);
        }
      }
    }

    &__placeholder {
      font-size: 16px;
      line-height: 24px;
      color: $color-placeholder;
      margin-left: 0;
      margin-right: 0;
      max-width: calc(100% - 30px);

      @include ellipsis-text();
    }

    &__value-container {
      padding: 11px 8px 11px 15px;
    }

    &__single-value {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      color: $color-main;
    }

    &__indicator {
      &-separator {
        display: none;
      }
    }

    &__dropdown-indicator {
      padding: 0;
      height: 46px;
      width: 30px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      svg {
        @include transition(transform);
      }
    }

    &__clear-indicator {
      padding: 0;
      height: 48px;
      width: 48px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__menu {
      border: none;
      border-radius: $radius;
      z-index: 3;
      box-shadow: 0px 51px 52px -14px rgba(0, 0, 0, 0.47);
      margin: 4px 0;
      background-color: #fff;

      &-list {
        padding: 0;
      }

      &-notice {
        &--no-options {
          font-size: 16px;
          line-height: 24px;
          color: $color-placeholder;
          padding: 12px 16px;
          margin: 0;
          text-align: center;
        }
      }
    }

    &__option {
      font-size: 16px;
      line-height: 24px;
      color: $color-main;
      padding: 12px 16px;
      cursor: pointer;
      background-color: transparent;
      transition: color 0.3s ease, background-color 0.3s ease;
      min-height: 40px;
      position: relative;

      &--is-selected,
      &--menu-is-open {
        background-color: transparent !important;
        cursor: default;
      }

      &:not(.cs__option--is-disabled) {
        @include hover() {
          background-color: transparent;
        }
        &:active {
          background-color: transparent;
        }
      }

      &--is-disabled {
        opacity: 0.3;
        cursor: default;
        -webkit-text-fill-color: currentcolor;
      }
    }
  }
}
