@import 'app/scss/variables';
@import 'app/scss/mixins';

.account {
  &-title {
    color: #fff;
    font-family: 'Mulish', sans-serif;
    font-weight: 700;

    @include breakpoint('mobile') {
      font-size: 24px;
      line-height: 32px;
    }
    @include breakpoint('tablet-up') {
      font-size: 40px;
      line-height: 48px;
    }
  }
  &-txt {
    color: #fff;
    @include breakpoint('mobile') {
      margin-top: 16px;
    }
    @include breakpoint('tablet-up') {
      margin-top: 24px;
    }
  }
  &-form {
    &-wrap {
      background: rgba(255, 255, 255, 0.1);
      border-radius: $radius * 2;
      position: relative;
      z-index: 1;

      @include breakpoint('mobile') {
        padding: 40px 16px;
      }

      @include breakpoint('tablet') {
        width: 453px;
        padding: 80px 24px 0;
        margin: 0 auto;
        min-height: 520px;
      }
      @include breakpoint('desktop') {
        width: 453px;
        padding: 80px 24px 0;
        min-height: 520px;
      }
    }
    &-row {
      margin-top: 16px;

      &._btn {
        margin-top: 40px;

        .btn {
          @include breakpoint('tablet-up') {
            width: 100%;
          }
        }
      }

      &-label {
        margin-bottom: 8px;
        color: #fff;
      }
    }

    &-resend-code {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      color: #fff;

      &-btn {
        cursor: pointer;
      }
    }

    .account-txt {
      padding-bottom: 24px;
    }
  }
  &-form-success {
    .btn {
      margin-top: 40px;
      @include breakpoint('tablet-up') {
        width: 100%;
      }
    }
  }
}