@import 'app/scss/variables';
@import 'app/scss/mixins';

.h-hero {
  &-section {
    color: $color-main;
    position: relative;
  }
  &-content {
    position: relative;

    @include breakpoint('mobile') {
      padding: 160px 0 436px;
    }
    @include breakpoint('tablet') {
      padding: 168px 0 428px;
    }
    @include breakpoint('desktop') {
      padding: 224px 0 360px;
    }
  }
  &-title {
    position: relative;
    z-index: 1;
    @include breakpoint('mobile') {
     font: 24px/32px 'Mulish';
    }
    @include breakpoint('tablet-up') {
      font: 40px/48px 'Mulish';
    }

    &:not(:first-child) {
      margin-top: 24px;
    }
  }
  &-img {
    position: absolute;
    
    @include breakpoint('mobile') {
      left: 50%;
      bottom: 0;
      transform: translate(-50%);
      width: 360px;
    }
    @include breakpoint('tablet') {
      right: -40px;
      bottom: -95px;
      width: 547px;
    }
    @include breakpoint('desktop') {
      right: 24px;
      bottom: -56px;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
      max-width: 605px;
    }
  }
}