@import 'app/scss/variables';
@import 'app/scss/mixins';

.o-img-gallery {
  &-section {
    position: relative;
    z-index: 2;
    background-color: $color-bg;
    height: 200vh;
  }
  &-content {
    position: sticky;
    left: 0;
    top: 0;
    overflow: hidden;
  }
  &-scale {
    transform-origin: center bottom;
    transform: scale(2);
    will-change: transform;
  }
  &-row {
    background-size: contain;
    position: relative;
    left: 50%;
    will-change: transform;
    
    @include breakpoint('desktop-down') {
      height: 264px;
      margin-top: 8px;
    }
    @include breakpoint('desktop') {
      height: 336px;
      margin-top: 16px;
    }


    &._top {
      background-image: url(../../assets/o-gallery/img-top.png);
      
      @include breakpoint('desktop-down') {
        width: 2137px;
        margin-left: -744px;
        transform: translateX(-200px);
      }
      @include breakpoint('desktop') {
        transform: translateX(-350px);
        margin-left: -1360px;
        width: 2720px;
      }
    }
    &._btm {
      background-image: url(../../assets/o-gallery/img-btm.png);
      transform-origin: 40% center;
      
      @include breakpoint('desktop-down') {
        width: 2205px;
        margin-left: -875px;
        transform: scale(1.1);
      }
      @include breakpoint('desktop') {
        width: 2806px;
        margin-left: -1110px;
        transform: scale(1.1);
      }
    }
  }
}