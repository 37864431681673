@import 'app/scss/variables';
@import 'app/scss/mixins';

.btn {
  border-radius: $radius;
  font: 16px/24px "DM Sans";
  color: #fff;
  letter-spacing: 0.35px;
  font-weight: 700;
  display: inline-flex;
  justify-content: center;
  vertical-align: top;
  cursor: pointer;
  position: relative;
  text-decoration: none;

  @include breakpoint('mobile') {
    width: 100%;
  }

  &._loading {
    pointer-events: none;
    .btn__content {
      opacity: 0;
    }
  }

  &._blocked {
    pointer-events: none;
  }

  &__content {
    display: inline-flex;
    vertical-align: top;
    text-align: center;
  }

  &__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 24px;
    height: 24px;
    margin: -12px 0 0 -12px;
    display: flex;
    background-image: url(../../assets/icons/loader.png);
    background-size: cover;
    animation: spin 2s linear infinite;
  }

  &-primary {
    border: none;
    padding: 12px 24px;
    background: linear-gradient(0deg, rgba(40, 167, 207, 0.84), rgba(40, 167, 207, 0.84)), radial-gradient(44.27% 202.44% at 79.07% -59.15%, #FF0CF5 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(38.13% 174.39% at 98.13% 112.2%, #37ECE1 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(60.42% 172.56% at 40.13% 118.29%, #BD00FF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(50.53% 231.1% at 5.33% 29.88%, #07D8D8 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    background-blend-mode: color-burn, normal, normal, normal, normal, normal;

    &:not(:disabled):hover {
      background: linear-gradient(0deg, #1B728D, #1B728D), radial-gradient(44.27% 202.44% at 79.07% -59.15%, #FF0CF5 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(38.13% 174.39% at 98.13% 112.2%, #37ECE1 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(60.42% 172.56% at 40.13% 118.29%, #BD00FF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(50.53% 231.1% at 5.33% 29.88%, #07D8D8 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    }

    &:disabled {
      background: #BCBCBE;
      cursor: default;
    }

    &._loading {
      background: linear-gradient(0deg, #1B728D, #1B728D), radial-gradient(44.27% 202.44% at 79.07% -59.15%, #FF0CF5 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(38.13% 174.39% at 98.13% 112.2%, #37ECE1 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(60.42% 172.56% at 40.13% 118.29%, #BD00FF 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(50.53% 231.1% at 5.33% 29.88%, #07D8D8 0%, rgba(255, 255, 255, 0) 100%), #FFFFFF;
    }
  }
  &-secondary {
    border: none;
    padding: 12px 24px;
    background-color: #7861F5;
    @include transition(background-color);

    &:not(:disabled):hover {
      background-color: #6148EC;
    }

    &:disabled {
      background: rgba(120, 97, 245, 0.3);
      cursor: default;
    }

    &._loading {
      background: rgba(120, 97, 245, 0.3);
    }
  }
  &-outlined {
    border: 2px solid #fff;
    padding: 10px 22px;
    background-color: rgba(#fff, .24);
    @include transition(background-color);

    &:not(:disabled):hover {
      background-color: rgba(#fff, .44);
    }

    &:disabled {
      background: transparent;
      cursor: default;
    }

    &._loading {
      background-color: rgba(#fff, .24);
    }
  }
}

@keyframes spin {
  100% {
    transform: rotate(-360deg);
  }
}