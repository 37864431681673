@import 'app/scss/variables';
@import 'app/scss/mixins';

.o-events {
  &-section {
    @include breakpoint('mobile') {
      padding: 64px 0;
    }
    @include breakpoint('tablet-up') {
      padding: 104px 0;
    }
  }
  &-content {
    @include breakpoint('tablet') {
      max-width: 688px;
      margin: 0 auto;
    }
    @include breakpoint('desktop') {
      max-width: 1062px;
      margin: 16px auto 0;
    }
  }
  &-card {
    background: #FFFFFF;
    box-shadow: 0px 40px 97px -37px rgba(189, 189, 189, 0.25);
    border-radius: $radius * 2;

    @include breakpoint('tablet-up') {
      display: flex;
    }

    &__desc {
      @include breakpoint('mobile') {
        padding: 40px 16px 0;
      }
      @include breakpoint('tablet') {
        flex: 0 0 50%;
        padding: 24px 0 24px 24px;
      }
      @include breakpoint('desktop') {
        flex: 0 0 595px;
        padding: 40px;
      }

      ._title {
        font-family: 'Mulish', sans-serif;
        font-weight: 700;

        @include breakpoint('mobile') {
          font-size: 24px;
          line-height: 32px;
        }
        @include breakpoint('tablet-up') {
          font-size: 40px;
          line-height: 48px;
        }
      }
      ._txt {
        @include breakpoint('mobile') {
          margin-top: 16px;
          line-height: 32px;
        }

        @include breakpoint('tablet-up') {
          font-size: 24px;
          line-height: 32px;
          margin-top: 24px;
        }
      }
    }
    &__img {
      display: flex;
      justify-content: center;
      align-items: flex-end;

      @include breakpoint('mobile') {
        padding: 18px 48px 0;
      }

      @include breakpoint('tablet') {
        flex: 0 0 50%;
        padding: 24px 24px 0;
      }

      @include breakpoint('desktop') {
        flex: 0 0 50%;
        padding: 40px 40px 0;
      }

      img {
        width: 100%;
        max-width: 296px;
        height: auto;

        @include breakpoint('tablet') {
          max-width: 270px;
        }
      }
    }
  }
  &-list-info {
    display: flex;

    @include breakpoint('mobile') {
      flex-direction: column;
      padding-top: 16px;
    }

    @include breakpoint('tablet-up') {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    @include breakpoint('tablet') {
      margin: 0 -12px;
    }

    &-item {
      @include breakpoint('mobile') {
        padding-top: 24px;
      }
      @include breakpoint('tablet') {
        padding: 32px 12px 0;
        width: 50%;
      }
      @include breakpoint('desktop') {
        max-width: 408px;
        padding-top: 64px;
      }

      &:nth-child(1) {
        @include breakpoint('mobile') {
          order: 1;
        }
        .o-events-list-info-item__title {
          @include textGradient {
            background: linear-gradient(90deg, #536CEA 0%, #AF4BA0 43.6%);
          }
        }
      }
      &:nth-child(2) {
        @include breakpoint('mobile') {
          order: 3;
        }
        .o-events-list-info-item__title {
          @include textGradient {
            background: linear-gradient(90deg, #AF4BA0 0%, #4F1D9F 43.6%);
          }
        }
      }
      &:nth-child(3) {
        @include breakpoint('mobile') {
          order: 2;
        }
        .o-events-list-info-item__title {
          @include textGradient {
            background: linear-gradient(90deg, #CD5AA9 0%, #854AB3 50%);
          }
        }
      }
      &:nth-child(4) {
        @include breakpoint('mobile') {
          order: 4;
        }
        .o-events-list-info-item__title {
          @include textGradient {
            background: linear-gradient(90deg, #854AB3 0%, #E68961 50%);
          }
        }
      }

      &__title {
        font-family: 'Mulish', sans-serif;
        font-weight: 700;
        display: inline-block;
        vertical-align: top;

        @include breakpoint('mobile') {
          font-size: 24px;
          line-height: 32px;
        }
        @include breakpoint('tablet-up') {
          font-size: 40px;
          line-height: 48px;
        }
      }

      &__txt {
        margin-top: 16px;

        @include breakpoint('mobile') {
          line-height: 32px;
        }

        @include breakpoint('tablet-up') {
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
}