@import 'app/scss/variables';
@import 'app/scss/mixins';

$slider-overflow-fix: 70px;

.h {
  &-slider {
    &-animation {
      &-section {
        @include breakpoint('mobile') {
          height: 1624px;
        }
        @include breakpoint('tablet') {
          height: 1808px;
        }
        @include breakpoint('desktop') {
          height: 200vh;
          min-height: 1600px;
          position: relative;
        }
      }
      &-sticky {
        position: sticky;
        left: 0;
        top: 0;
        overflow: hidden;
        height: 50%;

        @include breakpoint('desktop') {
          padding-bottom: $slider-overflow-fix;
          margin-bottom: -$slider-overflow-fix;
          box-sizing: content-box;
        }
      }
    }
    &-section {
      transform-origin: center center;
      @include breakpoint('desktop') {
        min-height: 800px;
        height: 100vh;
        display: flex;
        align-items: center;  
        position: relative;
        z-index: -2;
      }
    }
    &-swiper {
      position: relative;

      @include breakpoint('mobile') {
        height: 812px;
      }
      @include breakpoint('tablet') {
        height: 904px;
      }
      @include breakpoint('desktop') {
        height: 800px;
        width: 100%;
      }

      .swiper {
        @include breakpoint('desktop-down') {
          height: 100%;
        }
        @include breakpoint('desktop') {
          height: calc(100% + $slider-overflow-fix);
          padding-bottom: $slider-overflow-fix;
          margin-bottom: -$slider-overflow-fix;
        }

        &-slide {
          position: relative;

          // @include breakpoint('desktop-down') {
          //   &:after {
          //     content: '';
          //     display: block;
          //     position: absolute;
          //     left: 0;
          //     top: 0;
          //     right: 0;
          //     bottom: 0;
          //     z-index: 2;
          //   }
          // }
        }

        &-pagination {
          display: flex;
          justify-content: center;
          align-items: center;
          bottom: auto;
          z-index: 999;

          @include breakpoint('mobile') {
            top: 536px;
          }
          @include breakpoint('tablet') {
            top: 728px;
          }
          @include breakpoint('desktop') {
            flex-direction: column;
            align-items: center;
            left: 50%;
            width: 16px;
            right: auto;
            margin-left: 616px;
            transform: translate3d(-50%,-50%,0);
          }

          &-bullet {
            box-sizing: content-box;
            width: 8px;
            height: 8px;
            background-color: #d6ceff;
            opacity: 1;
            @include transition(background-color);
            
            @include breakpoint('desktop-down') {
              margin: 0 4px;
            }
            @include breakpoint('desktop') {
              margin: 8px 0 !important;
            }

            &.swiper-pagination-bullet-active {
              background-color: #7861f5;
              border: 4px solid #D6CEFF;
            }
          }
        }
      }
    }
    &-item {
      display: flex;
      height: 100%;
      flex-direction: column;

      > .wrapper {
        flex-grow: 1;
        display: flex;
      }

      &-content {
        flex-grow: 1;
        position: relative;

        ._illustration-1 {
          background-image: url(../../assets/h-slider/illustration-1.png);
          background-size: cover;
          display: block;
          position: absolute;

          @include breakpoint('tablet') {
            width: 370px;
            height: 259px;
            top: -40px;
            right: 50%;
            margin-right: 43px;
          }
          @include breakpoint('desktop') {
            width: 620px;
            height: 434px;
            top: -54px;
            right: 50%;
            margin-right: 188px;
          }
        }
        ._illustration-2 {
          @include breakpoint('desktop') {
            background-image: url(../../assets/h-slider/illustration-2.png);
            background-size: cover;
            display: block;
            position: absolute;
            width: 491px;
            height: 409px;
            bottom: 0;
            right: 50%;
            margin-right: 229px;
          }
        }
        ._illustration-3 {
          background-size: cover;
          display: block;
          position: absolute;
          z-index: 2;

          @include breakpoint('tablet') {
            background-image: url(../../assets/h-slider/illustration-3-t.png);
            width: 329px;
            height: 167px;
            top: 190px;
            left: 50%;
            margin-left: 32px;
          }
          @include breakpoint('desktop') {
            background-image: url(../../assets/h-slider/illustration-3-d.png);
            width: 466.5px;
            height: 238px;
            top: 85px;
            left: 50%;
            margin-left: 110px;
          }
        }
        ._illustration-4 {
          @include breakpoint('desktop') {
            background-image: url(../../assets/h-slider/illustration-4.png);
            background-size: cover;
            display: block;
            position: absolute;
            width: 234px;
            height: 291px;
            top: 262px;
            right: 50%;
            margin-right: 412px;
          }
        }
        ._illustration-5 {
          @include breakpoint('desktop') {
            background-image: url(../../assets/h-slider/illustration-5.png);
            background-size: cover;
            display: block;
            position: absolute;
            width: 411px;
            height: 344px;
            bottom: 0;
            left: 50%;
            margin-left: 309px;
          }
        }
        ._illustration-6 {
          background-image: url(../../assets/h-slider/illustration-6.png);
          background-size: cover;
          display: block;
          position: absolute;

          @include breakpoint('tablet') {
            width: 649px;
            height: 330px;
            top: 231px;
            left: 50%;
            margin-left: -324.5px;
            z-index: 2; 
          }
          @include breakpoint('desktop') {
            width: 887px;
            height: 450.5px;
            top: 188px;
            left: 50%;
            margin-left: -443.5px;
            z-index: 2; 
          }
        }
        ._illustration-7 {
          @include breakpoint('desktop') {
            background-image: url(../../assets/h-slider/illustration-7.png);
            background-size: cover;
            display: block;
            position: absolute;
            width: 253px;
            height: 273px;
            top: 282px;
            right: 50%;
            margin-right: 385px;
            z-index: 2;
          }
        }
        ._illustration-8 {
          @include breakpoint('desktop') {
            background-image: url(../../assets/h-slider/illustration-8.png);
            background-size: cover;
            display: block;
            position: absolute;
            width: 265px;
            height: 252px;
            top: 38px;
            left: 50%;
            margin-left: 375px;
            z-index: 2;
          }
        }
      }

      &-img {
        display: block;
        position: absolute;
        left: 50%;
        
        @include breakpoint('mobile') {
          width: 382px;
          height: 382px;
          top: 112px;
          margin-left: -192px;
          margin-top: -1px;
        }
        @include breakpoint('tablet') {
          width: 550px;
          height: 550px;
          top: 109px;
          margin-left: -276px;
          margin-top: -2px;
        }
        @include breakpoint('desktop') {
          width: 640px;
          height: 640px;
          top: 50%;
          margin: -323px 0 0 -321px;
        }
      }
      &-txt {
        font-size: 24px;
        line-height: 32px;
        position: absolute;

        @include breakpoint('mobile') {
          top: 592px;
          left: 0;
        }
        @include breakpoint('tablet') {
          top: 760px;
          left: 0;
          width: 392px;
        }
        @include breakpoint('tablet-up') {
        }

        &._1 {
          @include breakpoint('desktop') {
            width: 392px;
            bottom: 40px;
            left: 0;
          }
        }
        &._2 {
          @include breakpoint('desktop') {
            width: 392px;
            top: 40px;
            left: 0;
          }
        }
        &._3 {
          @include breakpoint('desktop') {
            width: 296px;
            top: 40px;
            right: 0;
          }
        }
        &._4 {
          @include breakpoint('desktop') {
            width: 296px;
            bottom: 40px;
            right: 0;
          }
        }
      }
      &-btn {
       @include breakpoint('desktop') {
        width: 312px;
        height: 80px;
        font-size: 32px;
        line-height: 40px;
        padding: 20px;
        position: absolute;
        top: 50%;
        margin-top: -40px;
        right: 52px;
        z-index: 4;
       }
      }
    }
    &-progress {
      position: absolute;
      left: 50%;
      background-image: url(../../assets/h-slider/progress-bg.svg);
      background-size: cover;

      @include breakpoint('mobile') {
        width: 478px;
        height: 478px;
        margin-left: -239px;
        top: 64px;
      }
      @include breakpoint('tablet') {
        width: 688px;
        height: 688px;
        margin-left: -344px;
        top: 40px;
      }
      @include breakpoint('desktop') {
        width: 800px;
        height: 800px;
        margin-left: -400px;
        top: 0;
      }

      &__indicator {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url(../../assets/h-slider/progress-indicator.svg) no-repeat right top;
        background-size: 50% 50%;
        transition: transform 0.5s ease;
      }
    }
    &-phone {
      position: absolute;
      left: 50%;
      background-image: url(../../assets/h-slider/phone.png);
      background-size: cover;
      z-index: 2;
      
      @include breakpoint('mobile') {
        width: 382px;
        height: 382px;
        top: 112px;
        margin-left: -191px;
      }
      @include breakpoint('tablet') {
        width: 550px;
        height: 550px;
        top: 109px;
        margin-left: -275px;
      }
      @include breakpoint('desktop') {
        width: 640px;
        height: 640px;
        top: 50%;
        margin: -320px 0 0 -320px;
      }
    }
  }
}