@import 'app/scss/variables';
@import 'app/scss/mixins';

.h-citizens {
  &-section {
    position: relative;
    overflow: hidden;
  }
  &-content {
    @include breakpoint('mobile') {
      padding: 64px 0;
    }
    @include breakpoint('tablet') {
      padding: 104px 0;
    }
    @include breakpoint('desktop') {
      padding: 82px 0;
      display: flex;
      align-items: center;
    }

    &__txt {
      flex-shrink: 0;
      h2 {
        font-family: 'Mulish', sans-serif;
        font-weight: 700;

        @include breakpoint('mobile') {
          font-size: 24px;
          line-height: 32px;
        }
        @include breakpoint('tablet-up') {
          font-size: 40px;
          line-height: 48px;
        }
      }
      p {
        line-height: 32px;

        @include breakpoint('mobile') {
          margin-top: 16px;
        }
        @include breakpoint('tablet-up') {
          font-size: 24px;
          margin-top: 24px;
          max-width: 500px;
        }
      }
    }

    &__img {
      @include breakpoint('mobile') {
        margin: 40px -16px 0;
      }
      @include breakpoint('tablet') {
        margin: 40px -40px 0;
      }
      @include breakpoint('desktop') {
        flex-grow: 1;
        margin-left: -78px;
        margin-right: -40px;
      }
    }
  }
  &-img {
    position: relative;
    width: 100%;
    max-width: 1121px;
    margin: 0 auto;

    &:before {
      content: '';
      display: block;
      padding-top: 56.7351%;
    }

    @include breakpoint('desktop') {
      @media (min-width: 1400px) {
        min-width: 1121px;
      }
    }

    &.is-inview {
      ._circle-block img, ._card-block img {
        transform: translateY(100%);
      }
      ._nature-block ._nature {
        opacity: 1;
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    ._phone-block {
      position: absolute;
      width: 47.636%;
      right: 19.8037%;
      bottom: 26.2579%;
      z-index: 1;
    }

    ._nature-block {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 60.7047%;

      ._shine {
        position: absolute;
        bottom: 0;
        left: 10.8744%;
        width: 76.8553%;
      }

      ._nature {
        opacity: 0;
        transition: opacity 2s linear 1s;
      }
    }

    ._circle-block {
      position: absolute;
      z-index: 1;
      width: 20.5056%;
      height: 48.2639%;
      overflow: hidden;
      bottom: 31.25%;
      left: 24.7191%;

      img {
        transition: transform 2s linear;
      }
    }

    ._card-block {
      position: absolute;
      z-index: 1;
      width: 26.9663%;
      height: 76.7361%;
      overflow: hidden;
      bottom: 59.5417%;
      left: 38.3895%;

      img {
        transition: transform 2s linear;
      }
    }

    ._card-w-block {
      position: absolute;
      left: 2.6762%;
      top: 6.1321%;
      width: 22.926%;

      > div {
        transition: transform 500ms cubic-bezier(0,0,0,1);
      }
    }

    ._card-b-block {
      position: absolute;
      left: 24.3533%;
      top: 8.7296%;
      width: 12.5335%;

      > div {
        transition: transform 500ms cubic-bezier(0,0,0,1);
      }
    }

    ._card-s-block {
      position: absolute;
      left: 39.5326%;
      top: 14.9371%;
      width: 6.4228%;

      > div {
        transition: transform 500ms cubic-bezier(0,0,0,1);
      }
    }

    ._card-p-block {
      position: absolute;
      left: 26.4942%;
      top: 44.3396%;
      width: 6.6012%;

      > div {
        transition: transform 500ms cubic-bezier(0,0,0,1);
      }
    }
    ._lines-block {
      position: absolute;
      left: 10.4371%;
      top: 22.7987%;
      width: 38.8046%;
    }
  }
}