@import 'app/scss/variables';
@import 'app/scss/mixins';

.o-application {
  &-section {
    background-repeat: no-repeat;
    background-position: center bottom;
    
    @include breakpoint('mobile') {
      background-image: url(../../assets/application-bg-m.svg);
      padding: 64px 0 116px;
    }
    @include breakpoint('tablet') {
      background-image: url(../../assets/application-bg-t.svg);
      padding-top: 40px;
      min-height: 800px;
    }
    @include breakpoint('desktop') {
      background-image: url(../../assets/application-bg-d.svg);
      padding-top: 160px;
      min-height: 800px;
    }
  }
  &-content {
    @include breakpoint('desktop') {
      display: flex;
      justify-content: space-between;
      width: 982px;
      margin: 0 auto;
    }

    &__txt {
      @include breakpoint('desktop') {
        width: 366px;
      }

      ._title {
        font-family: 'Mulish', sans-serif;
        font-weight: 700;

        @include textGradient {
          background: linear-gradient(90deg, #536CEA 0%, #AF4BA0 43.6%);
        }

        @include breakpoint('mobile') {
          font-size: 24px;
          line-height: 32px;
        }
        @include breakpoint('tablet-up') {
          font-size: 40px;
          line-height: 48px;
        }
      }

      ._txt {
        margin-top: 16px;
        line-height: 32px;
        color: $color-main;

        @include breakpoint('tablet-up') {
          font-size: 24px;
        }
      }
    }
    &__form {
      @include breakpoint('desktop') {
        width: 405px;
      }
    }
  }
  &-form {
    @include breakpoint('mobile') {
      margin-top: 40px;
    }
    @include breakpoint('tablet') {
      width: 405px;
      margin: 40px auto 0;
    }

    &-category {
      color: $color-main;
      margin-bottom: -8px;

      &:not(:first-of-type) {
        margin-top: 40px;
      }
    }
    &-row {
      margin-top: 16px;

      &._btn {
        margin-top: 40px;

        .btn {
          @include breakpoint('tablet-up') {
            width: 100%;
          }
        }
      }
    }
  }
  &-form-success {
    color: $color-main;

    @include breakpoint('mobile') {
      padding-top: 114px;
    }
    @include breakpoint('tablet') {
      padding-top: 164px;
      width: 405px;
      margin: 0 auto;
      text-align: center;
    }
    h6 {
      display: inline-block;
      vertical-align: top;
      font: 40px/48px 'Mulish';
      font-weight: 700;
    }
    p {
      margin-top: 24px;
    }
    .btn {
      margin-top: 40px;
      @include breakpoint('tablet-up') {
        width: 100%;
      }
    }
  }
}