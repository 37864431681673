@import 'app/scss/variables';
@import 'app/scss/mixins';

.o {
  &-cards {
    &-content {
      margin: 0 auto;
      @include breakpoint('mobile') {
        max-width: 328px;
        padding-bottom: 64px;
      }
      @include breakpoint('tablet') {
        max-width: 688px;
      }
      @include breakpoint('desktop') {
        max-width: 842px;
      }
    }
    &-grid {
      @include breakpoint('tablet-up') {
        display: flex;
        flex-wrap: wrap;
      }
      @include breakpoint('tablet') {
        margin: 0 -12px;
      }
      @include breakpoint('desktop') {
        margin: 0 -16px;
      }
  
      &-overlay {
        @include breakpoint('mobile') {
          display: none;
        }
        @include breakpoint('tablet-up') {
          cursor: pointer;
          position: fixed;
          left: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
          background-color: rgba(#000, .3);
          z-index: 3;
          opacity: 0;
          visibility: hidden;
          transition: opacity 0.8s ease-in-out, visibility 0.8s ease-in-out;
        }
      }
    }
  }
  &-card {
    perspective: 3200px;
    position: relative;

    @include breakpoint('mobile') {
      height: 421px;
      
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
    @include breakpoint('tablet') {
      margin: 24px 12px 0;
    }
    @include breakpoint('desktop') {
      margin: 32px 16px 0;
    }

    &._animating {
      z-index: 4;
    }

    &._half {
      @include breakpoint('tablet') {
        flex: calc(50% - 24px);
        height: 602px;
      }
      @include breakpoint('desktop') {
        flex: calc(50% - 32px);
        height: 600px;
      }

      .o-card-back {
        transform: rotateY(180deg);
      }

      &._opened .o-card-inner {
        transform: rotateY(180deg);
      }
    }

    &:not(._half) {
      @include breakpoint('tablet') {
        flex: calc(100% - 24px);
        height: 504px;
      }
      @include breakpoint('desktop') {
        flex: calc(100% - 32px);
        height: 600px;
      }

      .o-card-back {
        transform: rotateX(180deg);
      }

      &._opened .o-card-inner {
        transform: rotateX(180deg);
      }
    }

    &._opened {
      ~ .o-cards-grid-overlay {
        opacity: 1;
        visibility: visible;
      }
    }

    &-inner {
      position: relative;
      width: 100%;
      height: 100%;
      transition: transform 0.8s ease-in-out;
      transform-style: preserve-3d;
      box-shadow: 0px 40px 97px -37px rgba(189, 189, 189, 0.25);
      border-radius: $radius * 2;
    }

    &-front, &-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      border-radius: $radius * 2;
    }

    &-front {
      cursor: pointer;
    }

    &-back {
      background-color: #fff;
    }

    &.first-card {
      .o-card {
        &-front {
          display: flex;
          flex-direction: column;
          background-color: #fff;

          @include breakpoint('mobile') {
            padding: 40px 16px 0;
          }
          @include breakpoint('tablet') {
            padding: 24px 24px 0;
          }
          @include breakpoint('desktop') {
            padding: 40px 40px 0;
          }

          ._img {
            display: flex;
            align-items: flex-start;
            margin-top: auto;
            overflow: hidden;

            @include breakpoint('mobile') {
              padding-top: 16px;
            }
            @include breakpoint('tablet') {
              padding-top: 24px;
            }

            img {
              display: block;
              width: 100%;
              height: auto;
            }
          }

          ._title {
            font-family: 'Mulish', sans-serif;
            font-weight: 700;

            @include breakpoint('mobile') {
              font-size: 24px;
              line-height: 32px;
            }
            @include breakpoint('tablet-up') {
              font-size: 40px;
              line-height: 48px;
            }
          }

          ._txt {
            line-height: 32px;

            @include breakpoint('mobile') {
              margin-top: 16px;
            }

            @include breakpoint('tablet-up') {
              font-size: 24px;
              margin-top: 24px;
            }
          }
        }
        &-back {
          display: flex;

          @include breakpoint('mobile') {
            flex-direction: column;
          }
          @include breakpoint('tablet-up') {
            align-items: center;
            justify-content: space-between;
          }

          ._img {
            display: flex;
            align-items: flex-start;
            overflow: hidden;
            
            @include breakpoint('mobile') {
              margin-top: auto;
              justify-content: center;
              width: 100%;
              padding-top: 16px;

              img {
                margin-left: 16px;
                margin-right: 16px;
              }
            }
            @include breakpoint('tablet') {
              width: 357px;
              flex-shrink: 0;
              align-self: flex-end;
              max-height: 100%;
              border-top-right-radius: $radius * 2;
              border-bottom-right-radius: $radius * 2;
            }
            @include breakpoint('desktop') {
              width: 357px;
              flex-shrink: 0;
              margin-top: 60px;
              align-self: flex-end;
            }

            img {
              display: block;
              width: 100%;
              height: auto;
            }
          }

          p {
            line-height: 32px;

            @include breakpoint('mobile') {
              margin: 16px 16px 0;
            }
            @include breakpoint('tablet-up') {
              font-size: 24px;
              margin: 40px;
              width: 372px;
            }
          }
        }
      }
    }
    &.second-card {
      .o-card {
        &-front, &-back {
          display: flex;
          align-items: center;

          @include breakpoint('mobile') {
            padding: 16px;
          }
          @include breakpoint('tablet-up') {
            padding: 24px;
          }
        }
        &-front {
          background: radial-gradient(77.65% 89% at 50% 50%, rgba(255, 255, 255, 0.45) 0%, rgba(102, 56, 209, 0) 100%), linear-gradient(154.81deg, #6B7ED1 2.76%, #6433CF 97.24%);

          p {
            color: #fff;
            font-family: 'Mulish', sans-serif;
            font-weight: 700;

            @include breakpoint('mobile') {
              font-size: 56px;
              line-height: 72px;
            }
            @include breakpoint('tablet') {
              font-size: 56px;
              line-height: 80px;
            }
            @include breakpoint('desktop') {
              font-size: 64px;
              line-height: 96px;
            }
          }
        }
        &-back {
          p {
            line-height: 32px;
            @include breakpoint('tablet-up') {
              font-size: 24px;
            }
          }
        }
      }
    }
    &.third-card {
      .o-card {
        &-front {
          background-color: #fff;

          @include breakpoint('mobile') {
            padding: 16px;
          }
          @include breakpoint('tablet-up') {
            padding: 24px;
          }

          ._img {
            display: flex;
            align-items: flex-start;

            img {
              width: 100%;
              height: auto;
            }
          }

          p {
            font-family: 'Mulish', sans-serif;
            font-weight: 700;

            @include breakpoint('mobile') {
              font-size: 24px;
              line-height: 32px;
              margin-top: 8px;
            }
            @include breakpoint('tablet-up') {
              font-size: 40px;
              line-height: 48px;
            }
            @include breakpoint('tablet') {
              margin-top: 16px;
            }
            @include breakpoint('desktop') {
              margin-top: 24px;
            }
          }
        }
        &-back {
          ._img {
            display: flex;
            align-items: flex-start;

            img {
              width: 100%;
              height: auto;
            }
          }

          p {
            line-height: 32px;

            @include breakpoint('mobile') {
              margin: 0 16px 16px;
            }
            @include breakpoint('tablet-up') {
              font-size: 24px;
              margin: 24px 24px 0;
            }
          }
        }
      }
    }

    &-close-btn {
      width: 32px;
      height: 32px;
      position: absolute;
      cursor: pointer;
      display: flex;
      align-items: flex-start;

      @include breakpoint('mobile') {
        right: 16px;
        bottom: 16px;
      }
      @include breakpoint('tablet-up') {
        right: 24px;
        bottom: 24px;
      }
    }
    &-open-btn {
      width: 32px;
      height: 32px;
      position: absolute;
      cursor: pointer;
      display: flex;
      align-items: flex-start;

      @include breakpoint('mobile') {
        right: 16px;
        bottom: 16px;
      }
      @include breakpoint('tablet-up') {
        right: 24px;
        bottom: 24px;
      }
    }
  }
}