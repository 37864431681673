@import 'app/scss/variables';
@import 'app/scss/mixins';

.scroll-top {
  position: fixed;
  z-index: 998;
  transform: scale(0);
  @include transition(transform);

  @include breakpoint('mobile') {
    right: 16px;
    bottom: 16px;
  }

  @include breakpoint('tablet-up') {
    right: 80px;
    bottom: 40px;
  }

  &._visible {
    transform: scale(1);
  }

  &-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    padding: 8px;
    appearance: none;
    border-radius: 50%;
    cursor: pointer;
    background: #BCBCBE;
    box-shadow: 26px 29px 24px -21px rgba(0, 0, 0, 0.23);
    @include transition(background-color, box-shadow);

    @include breakpoint('mobile') {
      width: 48px;
      height: 48px;
    }
  
    @include breakpoint('tablet-up') {
      width: 80px;
      height: 80px;
    }
    
    @include hover() {
      background-color: #ADADAF;
      box-shadow: 26px 18px 24px -21px rgba(0, 0, 0, 0.12);
    }
    &:active {
      background: #9E9EA0;
      box-shadow: 26px 18px 24px -21px rgba(0, 0, 0, 0.08);
    }

    svg {
      width: 100%;
      height: 100%;
    }
  }
}