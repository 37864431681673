@mixin breakpoint($point) {
  @if $point == "mobile" {
    @media (min-width: 0) and (max-width: $media-mobile-max-width) {
      @content;
    }
  } @else if $point == "tablet" {
    @media (min-width: $media-mobile-max-width + 1) and (max-width: $media-tablet-max-width) {
      @content;
    }
  } @else if $point == "tablet-up" {
    @media (min-width: $media-mobile-max-width + 1) {
      @content;
    }
  } @else if $point == "desktop" {
    @media (min-width: $media-tablet-max-width + 1) {
      @content;
    }
  } @else if $point == "desktop-down" {
    @media (max-width: $media-tablet-max-width) {
      @content;
    }
  }
}

@mixin canHover {
  @media (any-hover: hover) {
    @content;
  }
}

@mixin hover() {
  @include canHover {
    &:hover {
      @content;
    }
  }
}

@mixin input {
  display: flex;
  width: 100%;
  background-color: #fff;
  border-radius: $radius;
  border: none;
  padding: 11px 15px;
  font: 16px/24px "DM Sans";
  font-weight: 400;
  color: $color-main;
  box-shadow: 0px 15px 51px -19px rgba(109, 109, 109, 0.21);

  &:not(:disabled) {
    &:focus {
      & ~ .control-input__border:before {
        opacity: 1;
      }
    }
  }

  &:disabled {
    color: #BCBCBE;
    opacity: 1;
    @include placeholderInput(#BCBCBE);
  }

  &._error {
    & ~ .control-input__border:before {
      background: $color-wrong;
      opacity: 1;
    }
  }

  &:-webkit-autofill {
    box-shadow: inset 0 0 0px 56px #fff;
  }

  @include placeholderInput($color-placeholder);
}

@mixin placeholderInput($color) {
  &::-webkit-input-placeholder {
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
  }
}

@mixin transition($args...) {
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-property: $args;
}

@mixin ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin heroLight {
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    mix-blend-mode: color-dodge;
    top: 0;

    @include breakpoint('mobile') {
      width: 1024px;
      height: 560px;
      left: 0;
      background-image: url(../assets/hero-bg-m.svg);
    }
    @include breakpoint('tablet') {
      width: 1440px;
      height: 810px;
      left: 0;
      background-image: url(../assets/hero-bg-t.svg);
    }
    @include breakpoint('desktop') {
      width: 1920px;
      height: 1040px;
      left: 50%;
      margin-left: -960px;
      background-image: url(../assets/hero-bg-d.svg);
    }
  }
}

@mixin textGradient {
  @content;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@mixin inputBorder {
  content: '';
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: $radius;
  background: linear-gradient(0deg, #28A7CF, #28A7CF),
  radial-gradient(44.27% 202.44% at 79.07% -59.15%, #FF0CF5 0%, rgba(255, 255, 255, 0) 100%),
  radial-gradient(38.13% 174.39% at 98.13% 112.2%, #37ECE1 0%, rgba(255, 255, 255, 0) 100%),
  radial-gradient(60.42% 172.56% at 40.13% 118.29%, #BD00FF 0%, rgba(255, 255, 255, 0) 100%),
  radial-gradient(50.53% 231.1% at 5.33% 29.88%, #2CB5B5 0%, rgba(255, 255, 255, 0) 100%),
  linear-gradient(0deg, #30AEC2, #30AEC2);
  background-blend-mode: color-burn, normal, normal, normal, normal, normal;
  @include transition(opacity);
}