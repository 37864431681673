@import 'variables';
@import 'mixins';

* {
  padding: 0;
  margin: 0;
  outline: none;
  box-sizing: border-box;
}

body, html, #root {
  width: 100%;
  height: 100%;
}

body {
  font: 16px/24px 'DM Sans', sans-serif;
  font-weight: 400;
  background-color: $color-bg;

  &._menu-opened {
    overflow: hidden;
  }

  &._freezed_scroll {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
}

ul, ol {
  list-style: none;
}

.wrapper {
  width: 100%;
  max-width: 1312px;
  margin: 0 auto;

  @include breakpoint('mobile') {
    padding-left: 16px;
    padding-right: 16px;
  }
  @include breakpoint('tablet') {
    padding-left: 40px;
    padding-right: 40px;
  }
  @include breakpoint('desktop') {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.visible {
  &-tablet {
    @include breakpoint('mobile') {
      display: none !important;
    }
    @include breakpoint('desktop') {
      display: none !important;
    }
  }
  &-desktop {
    @include breakpoint('desktop-down') {
      display: none !important;
    }
  }
}
.hidden {
  &-mobile {
    @include breakpoint('mobile') {
      display: none !important;
    }
  }
  &-tablet {
    @include breakpoint('tablet') {
      display: none !important;
    }
  }
  &-desktop {
    @include breakpoint('desktop') {
      display: none !important;
    }
  }
}