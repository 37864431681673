@import 'app/scss/variables';
@import 'app/scss/mixins';

.o-info {
  &-section {
    position: relative;
    z-index: 2;
    
    @include breakpoint('tablet-up') {
      height: 600vh;
      display: flex;
      flex-direction: column;
    }
  }
  &-step {
    &-section {
      @include breakpoint('tablet-up') {
        flex-grow: 1;
      }
    }
  }
  &-content {
    @include breakpoint('tablet-up') {
      position: sticky;
      top: 0;
      left: 0;
      bottom: 0;
      height: 100vh;
      margin: 0 auto;
      width: 100%;
    }
    @include breakpoint('tablet') {
      max-width: 768px;
    }
    @include breakpoint('desktop') {
      max-width: 1094px;
    }
    
    &-item {
      @include breakpoint('mobile') {
        padding: 40px 0 87px;
      }
      @include breakpoint('tablet-up') {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
      }

      &__txt {
        @include breakpoint('mobile') {
          opacity: 1 !important;
          transform: none !important;
          margin-top: 85px;
        }
        @include breakpoint('tablet-up') {
          margin-top: 40px;
          opacity: 0;
          transform: translateY(40px);
          will-change: transform, opacity;

          ._first & {
            opacity: 1;
          }
        }
        @include breakpoint('tablet') {
          width: 304px;
          margin-left: 48px;
        }
        @include breakpoint('desktop') {
          width: 406px;
        }

        h5 {
          font-weight: 700;
          font-family: 'Mulish', sans-serif;
          will-change: transform, opacity;

          @include breakpoint('mobile') {
            font-size: 24px;
            line-height: 32px;

            br {
              display: none;
            }
          }
          @include breakpoint('tablet-up') {
            font-size: 40px;
            line-height: 48px;
          }

          ._first & {
            @include textGradient {
              background: linear-gradient(90deg, #CD5AA9 0%, #854AB3 50%);
            }
          }

          ._second & {
            @include textGradient {
              background: linear-gradient(90deg, #536CEA 0%, #AF4BA0 43.6%);
            }
          }

          ._third & {
            @include textGradient {
              background: linear-gradient(90deg, #E68961 0%, #854AB3 50%);
            }
          }
        }

        p {
          color: $color-main;
          margin-top: 16px;
          line-height: 32px;

          @include breakpoint('tablet-up') {
            font-size: 24px;
          }
        }
      }
    }

    &-img {
      @include breakpoint('tablet-up') {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        display: flex;
      }

      .wrapper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

    }

    &-wrapper {
      display: flex;
      @include breakpoint('mobile') {
        flex-direction: column;
      }
      @include breakpoint('tablet-up') {
        height: 100%;
        justify-content: space-between;

        .o-info-img {
          display: none !important;
        }
      }
      @include breakpoint('tablet') {
        padding: 120px 0 80px;
      }
      @include breakpoint('desktop') {
        padding: 100px 0;
      }
    }

    &-progress {
      opacity: 1;
      position: absolute;
      top: 112px;
      bottom: 0;
      width: 2px;

      @include breakpoint('mobile') {
        display: none;
      }
      @include breakpoint('tablet') {
        left: 50%;
        margin-left: -332px;
      }
      @include breakpoint('desktop') {
        left: -20px;
        @media (min-width: 1300px) {
          left: -83px;
        }
      }
      
      &__line {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(180deg, #CC5AAA 0%, #5E69E2 36.98%, #D8806E 70.83%, rgba(0, 0, 0, 0) 100%);
        will-change: transform;

        ._dot {
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          left: -3px;

          &:nth-child(1) {
            background-color: #cc5aaa;
            top: 0;
          }
          &:nth-child(2) {
            background-color: #8265D1;
            top: 36.98%;
          }
          &:nth-child(3) {
            background-color: #C37D82;
            top: 70.83%;
          }
        }
      }


      &__dot {
        display: block;
        width: 24px;
        height: 24px;
        border: 8px solid $color-bg;
        border-radius: 50%;
        left: 0;
        top: 0;
        position: absolute;
        box-sizing: content-box;
        background: #cc5aaa;
        margin-top: -8px;
        margin-left: -19px;
      }
    }
  }
  &-img {
    position: relative;
    height: 100%;
    display: flex;
    align-items: flex-start;
    
    @include breakpoint('mobile') {
      opacity: 1 !important;
      width: 100%;
      max-width: 208px;
      height: 424px;
      justify-content: flex-end;
      margin-left: 120px;
      align-self: center;
    }
    
    @include breakpoint('tablet') {
      opacity: 1;
      width: 208px;
      height: 424px;
    }
    @include breakpoint('desktop') {
      opacity: 1;
      width: 297px;
      height: 600px;
      margin-right: 90px;
    }

    .img {
      &-hardware {
        position: relative;
        z-index: 1;
        width: 100%;
        height: auto;
      }
      &-phone {
        position: absolute;
        background-size: cover;
        opacity: 0;
        transition: opacity 0.3s ease-in;
        background-size: auto 100%;
        background-position:  center top;
        top: 2.1595%;
        right: 5.0505%;
        bottom: 2.1595%;
        left: 5.0505%;
        border-radius: 16px;
        will-change: opacity;

        &._first {
          background-image: url('../../assets/o-info/phone-1.png');
        }
        &._second {
          background-image: url('../../assets/o-info/phone-2.png');
        }
        &._third {
          background-image: url('../../assets/o-info/phone-3.png');
        }

        &._visible {
          opacity: 1;
        }
      }
      &-item {
        position: absolute;
        bottom: 0;
        background-size: cover;
        opacity: 0;
        transition: opacity 0.3s ease-in;
        z-index: 2;
        will-change: opacity;

        &._visible {
          opacity: 1;
        }

        &._first {
          width: 93.266%;
          height: 37.3754%;
          right: 76.0943%;
          bottom: -9.4684%;
          background-image: url(../../assets/o-info/img-1.png);
        }
        &._second {
          width: 140.404%;
          height: 58%;
          right: 37.3737%;
          bottom: -15.6667%;
          background-image: url(../../assets/o-info/img-2.png);
        }
        &._third {
          width: 122.2222%;
          height: 64.1196%;
          right: 43.771%;
          bottom: -23.2558%;
          background-image: url(../../assets/o-info/img-3.png);
        }
      }
    }
  }
}