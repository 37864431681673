@import 'app/scss/variables';
@import 'app/scss/mixins';

.o-intro {
  &-content {
    display: flex;
    flex-direction: column;
    
    @include breakpoint('mobile') {
      padding: 64px 0 40px;
    }
    @include breakpoint('tablet-up') {
      align-items: center;
      padding: 104px 0;
      text-align: center;
    }
  }
  &-title {
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    
    @include textGradient {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(89.99deg, #09D1D9 -2.47%, #DA5AF9 49.83%, #20A2F2 100%);
    }

    @include breakpoint('mobile') {
      font-size: 24px;
      line-height: 32px;
    }
    @include breakpoint('tablet') {
      font-size: 48px;
      line-height: 56px;
    }
    @include breakpoint('desktop') {
      font-size: 54px;
      line-height: 72px;
    }
  }
  &-txt {
    @include breakpoint('mobile') {
      margin-top: 16px;
    }
    @include breakpoint('tablet-up') {
      font-size: 24px;
      line-height: 32px;
      width: 624px;
    }
    @include breakpoint('tablet') {
      margin-top: 24px;
    }
    @include breakpoint('desktop') {
      margin-top: 40px;
    }
  }
}