@import 'app/scss/variables';
@import 'app/scss/mixins';

.o-phone {
  &-section {
    overflow: hidden;
  }
  &-content {
    position: relative;
    
    @include breakpoint('mobile') {
      margin-bottom: 40px;
    }
    @include breakpoint('tablet') {
      height: 1402px;
      margin-bottom: 100px;
    }
    @include breakpoint('desktop') {
      height: 1444px;
      margin-bottom: 100px;
    }
  }
  &-txt {
    font: 40px/48px 'Mulish';
    font-weight: 700;
    
    @include textGradient {
      background: linear-gradient(90deg, #6B7ED1 0%, #6433CF 98.83%);
    }

    @include breakpoint('mobile') {
      padding: 64px 0;
    }

    @include breakpoint('tablet-up') {
      margin: 0 auto;
      width: 406px;
    }
    @include breakpoint('tablet') {
      padding: 498px 0 380px;
    }
    @include breakpoint('desktop') {
      padding: 482px 0;
    }
  }
  &-wrap {
    @include breakpoint('mobile') {
      display: none;
    }
    @include breakpoint('tablet-up') {
      position: absolute;
      display: flex;
      justify-content: center;
      left: 0;
      right: 0;
    }
    @include breakpoint('tablet') {
      top: 104px;
      bottom: 104px;
    }
    @include breakpoint('desktop') {
      top: 40px;
      bottom: 40px;
    }
  }
  &-scanner {
    position: relative;
    z-index: 2;

    @include breakpoint('tablet') {
      height: 340px;
      width: 688px;
    }
    @include breakpoint('desktop') {
      height: 416px;
      width: 842px;
    }

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 100%;
      right: 0;
      margin-top: -3px;
      height: 100vh;
      background-color: $color-bg;
      bottom: 0;
      z-index: -1;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}