@import 'app/scss/variables';
@import 'app/scss/mixins';

.h-vm {
  &-section {
    color: $color-main;
    background-repeat: no-repeat;
    background-position: center bottom;
    position: relative;
    overflow: hidden;
    z-index: 2;

    @include breakpoint('mobile') {
      background-image: url(../../assets/vm-bg-m.svg);
    }
    @include breakpoint('tablet') {
      background-image: url(../../assets/vm-bg-t.svg);
    }
    @include breakpoint('desktop') {
      background-image: url(../../assets/vm-bg-d.svg);
    }
  }
  &-content {
    position: relative;
    @include breakpoint('mobile') {
      padding: 72px 0 87px;
    }
    @include breakpoint('tablet') {
      padding: 88px 0 84px;
      margin: 0 auto;
      width: 504px;
    }
    @include breakpoint('desktop') {
      padding: 88px 0 96px 219px;
    }
  }
  &-item {
    will-change: opacity;
    &:not(:first-child) {
      @include breakpoint('mobile') {
        padding-top: 39px;
      }
      @include breakpoint('tablet') {
        padding-top: 40px;
      }
      @include breakpoint('desktop') {
        padding-top: 56px;
      }
    }

    .is-inview {
      opacity: 1;
    }

    &__title {
      text-transform: uppercase;
      opacity: 0.35;
      transition: opacity .5s cubic-bezier(0.25,1,0.5,1);

      @include breakpoint('desktop-down') {
        font: 24px/32px 'Mulish';
        font-weight: 700;
      }
      @include breakpoint('desktop') {
        font: 40px/48px 'Mulish';
        font-weight: 700;
      }
    }
    &__txt {

      @include breakpoint('mobile') {
        font: 40px/48px 'Mulish';
        margin-top: 16px;
      }
      @include breakpoint('tablet') {
        font: 48px/56px 'Mulish';
        margin-top: 16px;
      }
      @include breakpoint('desktop') {
        font: 72px/80px 'Mulish';
        margin-top: 24px;
        max-width: 733px;
      }

      span {
        display: block;
        opacity: 0.5;
        letter-spacing: -1px;
        transition: opacity .5s cubic-bezier(0.25,1,0.5,1);
      }
    }
  }
}