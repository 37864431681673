@import 'app/scss/variables';
@import 'app/scss/mixins';

.control-verification-code {
  .code-input {
    display: flex;
    justify-content: space-between;
    margin: 0 -4px;
  }
  .code-item {
    flex-grow: 1;
    margin: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    position: relative;
    z-index: 5;
    padding: 11px 15px;
    font: 16px/24px "DM Sans";
    font-weight: 400;
    color: $color-main;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      border-radius: $radius;
      background-color: #fff;
      z-index: -1;
      @include transition(margin);
    }
    &:after {
      @include inputBorder();
      z-index: -2;
      opacity: 1;
    }

    @include breakpoint('mobile') {
      height: 64px;
    }
    @include breakpoint('tablet-up') {
      height: 80px;
    }

    &__inactive {
      color: $color-placeholder;
    }
    &__selected {
      &:before {
        margin: 1px;
      }
    }
  }
}