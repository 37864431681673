@import 'app/scss/variables';
@import 'app/scss/mixins';

.footer {
  &-section {
    background-color: #000;
    padding: 40px 0;
    color: #fff;
  }
  &-row {
    display: flex;
    align-items: center;

    @include breakpoint('mobile') {
      flex-direction: column;
      justify-content: center;
    }
    @include breakpoint('tablet-up') {
      justify-content: space-between;
    }

    &._btm {
      @include breakpoint('mobile') {
        flex-direction: column-reverse;
      }
      @include breakpoint('desktop-down') {
        margin-top: 40px;
        flex-wrap: wrap;
      }
      @include breakpoint('desktop') {
        margin-top: 10px;
        position: relative;
      }
    }
  }
  &-logo {
    display: flex;
    align-items: flex-start;

    img {
      @include breakpoint('mobile') {
        width: 155px;
        height: 48px;
      }
      @include breakpoint('tablet-up') {
        width: 206px;
        height: 64px;
      }
    }
  }
  &-menu {
    display: flex;

    @include breakpoint('mobile') {
      flex-direction: column;
      margin-top: 24px;
    }
    
    li {
      @include breakpoint('mobile') {
        padding-top: 24px;
        text-align: center;
      }
      @include breakpoint('tablet-up') {
        &:not(:first-child) {
          padding-left: 32px;
        }
      }
      @include breakpoint('desktop') {
        padding-left: 40px;
      }
    }
    a {
      display: inline-flex;
      vertical-align: top;
      align-items: center;
      font-weight: 700;
      color: #fff;
      letter-spacing: 0.35px;

      svg {
        margin-right: 8px;
        path {
          fill: currentColor;
        }
      }
    }
  }
  &-copy {
    line-height: 32px;

    @include breakpoint('mobile') {
      text-align: center;
      margin-top: 40px;
    }
  }
  &-social {
    display: flex;

    @include breakpoint('mobile') {
      justify-content: center;
    }

    &__label {
      letter-spacing: 0.35px;
    }

    &__menu {
      display: flex;

      li {
        padding-left: 24px;
        display: flex;
        align-items: flex-start;
      }
    }
  }
  &-designed {
    display: flex;
    align-items: center;

    @include breakpoint('mobile') {
      margin-top: 40px;
      order: -1;
    }
    @include breakpoint('tablet') {
      width: 100%;
      margin-top: 40px;
    }
    @include breakpoint('desktop') {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
    }

    span {
      padding: 0 8px;
    }

    a {
      color: inherit;
    }
  }
}