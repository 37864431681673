@import 'app/scss/variables';
@import 'app/scss/mixins';

.cp-img {
  position: relative;

  @include breakpoint('mobile') {
    margin-left: 20px;
  }

  ._arrow {
    position: absolute;
    background-image: url(../../assets/contact-page-img-arrow.svg);
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center bottom;
    width: 11.0865%;
    height: 31.0406%;
    bottom: 18%;
    transform-origin: 50% 85%;
    
    @keyframes arrowRotateLeft {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }

    @keyframes arrowRotateRight {
      from {
        transform: rotate(180deg);
      }
      to {
        transform: rotate(540deg);
      }
    }

    &._left {
      left: 14%;
      animation: arrowRotateLeft 6s linear infinite backwards;

    }
    &._right {
      left: 67%;
      animation: arrowRotateRight 7s linear infinite backwards;
    }
  }

  img {
    display: block;
    width: 100%;
    max-width: 451px;
    height: auto;
  }
}