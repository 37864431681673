@import "src/app/scss/variables";
@import "src/app/scss/mixins";

.control-file {
  position: relative;

  input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
  }

  &-btn {
    display: inline-flex;
    align-items: center;
    vertical-align: top;
    cursor: pointer;
    padding: 8px 0;
    color: $color-main;

    svg {
      margin-right: 8px;
      path {
        fill: currentColor;
      }
    }
  }
  &-attachemnt {
    display: flex;
    padding: 8px 0;

    &__name {
      flex-grow: 1;
      display: flex;
      color: $color-main;

      svg {
        margin-right: 8px;
        flex-shrink: 0;
        path {
          fill: currentColor;
        }
      }

      span {
        word-break: break-all;
        padding-right: 8px;
      }
    }
    &__remove {
      flex-shrink: 0;
      display: flex;
      align-items: flex-start;
      cursor: pointer;
    }
  }
}
