@import 'app/scss/variables';
@import 'app/scss/mixins';

.h {
  &-parallax {
    &-img {
      position: relative;
      display: flex;
      justify-content: center;
      overflow: hidden;

      @include breakpoint('mobile') {
        padding-bottom: 80px;
        margin-bottom: -80px;
      }
      @include breakpoint('tablet-up') {
        padding-bottom: 96px;
        margin-bottom: -96px;
      }

      ._circle {
        display: block;
        border-radius: 50%;
        background: linear-gradient(127.67deg, #523BD0 18.16%, #7861F5 88.41%);
        box-shadow: inset 59px 47px 174px #392898;
        position: relative;

        @include breakpoint('mobile') {
          width: 328px;
          height: 328px;
          margin-top: 117px;
        }
        @include breakpoint('tablet') {
          width: 504px;
          height: 504px;
          margin: 82px 0 27px;
        }
        @include breakpoint('desktop') {
          width: 639px;
          height: 639px;
          margin-top: 236px;
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: #E2E2E9;
          box-shadow: 33px 26px 37px rgba(0, 0, 0, 0.25);
          border-radius: 50%;

          @include breakpoint('mobile') {
            width: 141px;
            height: 141px;
          }
          @include breakpoint('tablet') {
            width: 217px;
            height: 217px;
          }
          @include breakpoint('desktop') {
            width: 275px;
            height: 275px;
          }
        }
      }

      ._blue-ball-1 {
        position: absolute;

        @include breakpoint('tablet') {
          width: 108px;
          height: 108px;
          top: 447px;
          right: 50%;
          margin-right: 305px;
          z-index: 1;
        }
        @include breakpoint('desktop') {
          width: 181px;
          height: 181px;
          top: 479px;
          right: 50%;
          margin-right: 524px;
        }

        div {
          position: relative;
          width: 100%;
          height: 100%;
        }

        i {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-image: url(../../assets/h-parallax/blue-ball-1.svg);
          background-size: cover;
        }
      }
      ._blue-ball-2 {
        position: absolute;
        z-index: 1;

        @include breakpoint('tablet') {
          width: 275px;
          height: 275px;
          top: 28px;
          left: 50%;
          margin-left: 52px;

          i {
            background-image: url(../../assets/h-parallax/blue-ball-2-t.svg);
          }
        }
        @include breakpoint('desktop') {
          width: 328px;
          height: 328px;
          top: 122px;
          left: 50%;
          margin-left: 82px;

          i {
            background-image: url(../../assets/h-parallax/blue-ball-2.svg);
          }
        }

        div {
          position: relative;
          width: 100%;
          height: 100%;
        }

        i {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
      }
      ._circle-s {
        position: absolute;

        @include breakpoint('tablet') {
          width: 257px;
          height: 227px;
          top: 433px;
          left: 50%;
          margin-left: -25px;
        }
        @include breakpoint('desktop') {
          width: 294px;
          height: 259px;
          top: 658px;
          left: 50%;
          margin-left: 77px;
        }

        div {
          position: relative;
          width: 100%;
          height: 100%;
        }

        i {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-image: url(../../assets/h-parallax/circle-s.svg);
          background-size: cover;
        }
      }
      ._white-ball-1 {
        position: absolute;
        background-size: cover;

        @include breakpoint('mobile') {
          width: 135px;
          height: 129px;
          top: 0;
          right: 50%;
          margin-right: 18px;
        }
        @include breakpoint('tablet') {
          width: 126px;
          height: 120px;
          top: 82px;
          right: 50%;
          margin-right: 218px;
        }
        @include breakpoint('desktop') {
          width: 243.5px;
          height: 232px;
          top: 97.5px;
          right: 50%;
          margin-right: 320px;
        }

        div {
          position: relative;
          width: 100%;
          height: 100%;
        }

        i {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-image: url(../../assets/h-parallax/white-ball-1.png);
          background-size: cover;
        }
      }
      ._white-ball-2 {
        @include breakpoint('desktop') {
          position: absolute;
          width: 159px;
          height: 151.5px;
          left: 50%;
          margin-left: 555px;
          bottom: 20px;

          div {
            position: relative;
            width: 100%;
            height: 100%;
          }
  
          i {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../assets/h-parallax/white-ball-2.png);
            background-size: cover;
          }
        }
      }
      ._disk {
        @include breakpoint('desktop') {
          position: absolute;
          z-index: 1;
          width: 312px;
          height: 312.5px;
          left: 50%;
          margin-left: 492px;
          top: 155px;

          div {
            position: relative;
            width: 100%;
            height: 100%;
          }
  
          i {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-image: url(../../assets/h-parallax/disk.png);
            background-size: cover;
          }
        }
      }
      ._disk-big {
        position: absolute;

        @include breakpoint('mobile') {
          width: 233px;
          height: 210px;
          top: 24px;
          left: 50%;
          margin-left: 13px;
        }
        @include breakpoint('tablet') {
          width: 291px;
          height: 252px;
          top: 0px;
          left: 50%;
          margin-left: 164px;
        }
        @include breakpoint('desktop') {
          width: 501px;
          height: 451px;
          top: 40px;
          left: 50%;
          margin-left: 201px;
        }

        div {
          position: relative;
          width: 100%;
          height: 100%;
        }

        i {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-image: url(../../assets/h-parallax/disk-big.svg);
          background-size: cover;
        }
      }
      ._disk-small {
        position: absolute;

        @include breakpoint('mobile') {
          width: 152px;
          height: 139px;
          top: 350px;
          right: 50%;
          margin-right: 80px;
        }
        @include breakpoint('tablet') {
          width: 175px;
          height: 159px;
          top: 418px;
          right: 50%;
          margin-right: 166px;
        }
        @include breakpoint('desktop') {
          width: 276px;
          height: 251px;
          top: 397px;
          right: 50%;
          margin-right: 241px;
        }

        div {
          position: relative;
          width: 100%;
          height: 100%;
        }

        i {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-image: url(../../assets/h-parallax/disk-small.svg);
          background-size: cover;
        }
      }
      ._cursor {
        position: absolute;

        @include breakpoint('mobile') {
          width: 117px;
          height: 126px;
          top: 389px;
          right: 50%;
          margin-right: 46px;
        }
        @include breakpoint('tablet') {
          width: 144px;
          height: 143px;
          top: 448px;
          right: 50%;
          margin-right: 122px;
        }
        @include breakpoint('desktop') {
          width: 209px;
          height: 224px;
          top: 483px;
          right: 50%;
          margin-right: 235px;
        }

        div {
          position: relative;
          width: 100%;
          height: 100%;
        }

        i {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-image: url(../../assets/h-parallax/cursor.png);
          background-size: cover;
        }
      }
      ._cone {
        position: absolute;

        @include breakpoint('tablet') {
          width: 238px;
          height: 180px;
          top: 338px;
          left: 50%;
          margin-left: 252px;
        }
        @include breakpoint('desktop') {
          width: 290px;
          height: 219.5px;
          top: 462px;
          left: 50%;
          margin-left: 335px;
        }

        div {
          position: relative;
          width: 100%;
          height: 100%;
        }

        i {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-image: url(../../assets/h-parallax/cone.png);
          background-size: cover;
        }
      }
    }
    &-content {
      @include breakpoint('mobile') {
        padding-top: 70px;
      }
      @include breakpoint('tablet') {
        padding-top: 40px;
      }
      @include breakpoint('desktop') {
        padding-top: 34px;
      }
      p {
        font-size: 24px;
        line-height: 32px;
        
        @include breakpoint('mobile') {
          height: 160px;
          max-width: 300px;
        }
        @include breakpoint('tablet-up') {
          max-width: 392px;
        }
      }
    }
    &-circle-logo {
      position: absolute;
      background-image: url(../../assets/h-parallax/circle-logo.png);
      background-size: cover;
      left: 50%;
      border-radius: 50%;
      box-shadow: 26px 29px 24px -21px rgba(0, 0, 0, 0.23);

      &._pinned {
        z-index: 2;
      }

      @include breakpoint('mobile') {
        width: 94px;
        height: 94px;
        margin-left: -47px;
        top: 234px;
      }
      @include breakpoint('tablet') {
        width: 146px;
        height: 146px;
        margin-left: -73px;
        top: 263px;
      }
      @include breakpoint('desktop') {
        width: 184px;
        height: 184px;
        margin-left: -92px;
        top: 465px;
      }
    }
  }
  &-phone {
    &-content {
      display: flex;
      align-items: center;
      justify-content: center;

      @include breakpoint('mobile') {
        flex-direction: column;
        margin-top: 80px;
      }
      @include breakpoint('tablet') {
        margin-top: 80px;
      }
      @include breakpoint('desktop') {
        margin-top: 142px;
      }
    }
    &-img {
      position: relative;

      @include breakpoint('mobile') {
        margin: 0 auto;
      }
      @include breakpoint('tablet') {
        margin: 0 52px;
      }
      @include breakpoint('desktop') {
        margin: 0 85px;
      }

      img {
        display: block;
        height: auto;
        @include breakpoint('mobile') {
          width: 208px;
          margin: 10px 12px 0;
        }
        @include breakpoint('tablet') {
          width: 180px;
          margin: 9px 10px 0;
        }
        @include breakpoint('desktop') {
          width: 300px;
          margin: 17px 17px 0;
        }
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        background-image: url(../../assets/h-parallax/phone-border.png);
        background-size: cover;
        z-index: 2;
        left: 0;
        top: 0;

        @include breakpoint('mobile') {
          width: 272px;
          height: 514px;
        }
        @include breakpoint('tablet') {
          width: 235px;
          height: 444px;
        }
        @include breakpoint('desktop') {
          width: 392.5px;
          height: 741.5px;
        }
      }
    }
    &-counter {
      background-size: contain;
      background-position: center center;
      background-repeat: no-repeat;

      @include breakpoint('mobile') {
        margin-top: 24px;
      }

      &._left {
        background-image: url(../../assets/h-parallax/life-l.svg);
      }
      &._right {
        background-image: url(../../assets/h-parallax/life-r.svg);
      }

      &__value {
        font-family: 'Mulish', sans-serif;
        font-weight: 700;
        color: #ebebeb;
        background-color: $color-bg;
        @include transition(opacity);

        @include breakpoint('mobile') {
          font-size: 124px;
          line-height: 156px;
        }
        @include breakpoint('tablet') {
          font-size: 84px;
          line-height: 105px;
        }
        @include breakpoint('desktop') {
          font-size: 166px;
          line-height: 213px;
        }

        &._completed {
          opacity: 0;
        }
      }
    }
  }
  &-tree {
    &-section {
      position: relative;
      background-color: $color-bg;
      z-index: 1;

      > .wrapper {
        @include breakpoint('tablet') {
          min-height: 150vh;
        }
       }
    }
    &-content {
      position: sticky;
      left: 0;
      overflow: hidden;
      
      @include breakpoint('mobile') {
        padding-top: 64px;
      }
      @include breakpoint('tablet') {
        padding-top: 90px;
        top: -50px;
      }
      @include breakpoint('desktop') {
        padding-top: 210px;
        top: -100px;
      }
    }
    &-img {
      display: flex;
      align-items: flex-start;
      position: relative;
      margin: 0 auto;
      overflow: hidden;
      background-color: rgba(245,245,247,1);

      @include breakpoint('mobile') {
        width: 328px;
      }
      @include breakpoint('tablet') {
        width: 512px;
      }
      @include breakpoint('desktop') {
        width: 844px;
      }

      img {
        position: relative;
        width: 100%;
        height: auto;
      }

      &__gradient {
        position: absolute;
        background: radial-gradient(circle, rgba(245,245,247,1) 31%, rgba(151,135,241,1) 44%, rgba(245,245,247,1) 55%);
        background-position: center center;
        background-repeat: no-repeat;
        border-radius: 50%;
        left: 50%;
        background-size: 1% 1%;

        &._animate {
          animation: treeBgScale 1.5s linear infinite;
        }

        @keyframes treeBgScale {
          from {
            background-size: 1% 1%;
          }
          to {
            background-size: 160% 160%;
          }
        }

        width: 200%;
        height: 200%;
        top: -60%;
        left: -50%;
      }
    }
    &-heart {
      position: absolute;
      left: 50%;
      top: 0;
      will-change: transform, opacity;
      
      @include breakpoint('tablet') {
        opacity: 0.2;
        transform: scale(0.55) translateY(-470px);
      }
      @include breakpoint('desktop') {
        opacity: 0.2;
        transform: scale(0.55) translateY(-668px);
      }

      @keyframes beat{
        0% { transform: scale(1); }
        10% { transform: scale(1.06); }
        30% { transform: scale(.95); }
        40% { transform: scale(1); }
        100% { transform: scale(1); }
      }
      
      @include breakpoint('mobile') {
        width: 144px;
        margin-left: -72px;
        margin-top: 113px;
      }
      @include breakpoint('tablet') {
        width: 201px;
        margin-left: -100px;
      }
      @include breakpoint('desktop') {
        width: 315px;
        margin-left: -157px;
      }

      img {
        display: block;
        width: 100%;
        height: auto;

        &._animate {
          animation: beat 1.5s infinite;
        }
      }
    }
    &-txt {
      &-section {
        position: relative;
        z-index: 3;

        @include breakpoint('mobile') {
          margin: 16px 0 32px;
        }
        @include breakpoint('tablet') {
          margin-bottom: 80px;
        }
        @include breakpoint('desktop') {
          margin-bottom: 104px;
        }
      }
      &-content {
        @include breakpoint('tablet-up') {
          display: flex;
          justify-content: space-between;
        }
  
        p {
          font-size: 24px;
          line-height: 32px;
  
          @include breakpoint('mobile') {
            padding-top: 24px;
          }
          @include breakpoint('tablet') {
            width: '100%';
          }
          @include breakpoint('desktop') {
            width: 624px;
            margin-top: -166px;
          }
        }
      }
    }
  }
  &-animation-scrolltrigger {
    position: relative;
  }
}