@import 'app/scss/variables';
@import 'app/scss/mixins';

.control-phone {
  .PhoneInput {
    position: relative;
    padding: 1px;
    border-radius: $radius;
    background-color: #fff;

    &:before {
      @include inputBorder();
    }

    &.PhoneInput--focus {
      &:before {
        opacity: 1;
      }
    }
  }
  &._error .PhoneInput {
    &:before {
      background: $color-wrong;
      opacity: 1;
    }
  }
  .PhoneInputCountry {
    width: 72px;
    height: 40px;
    border-right: 1px solid #EBEBEB;
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -20px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  .PhoneInputCountryIcon {
    width: 24px;

    img {
      display: block;
    }
  }
  .PhoneInputCountrySelectArrow {
    width: 24px;
    height: 24px;
    background-image: url(../../assets/icons/arrow-down.svg);
  }
  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
  }
  .PhoneInputInput {
    @include input();
    padding-left: 79px;
    position: relative;
  }
}