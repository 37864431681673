@import 'app/scss/variables';
@import 'app/scss/mixins';

.control {
  &-input {
    position: relative;
    padding: 1px;
    &__label {
      display: block;
      margin-bottom: 8px;
    }

    input, textarea {
      @include input();
      position: relative;
      z-index: 1;
    }

    textarea {
      resize: none;
      height: 118px;

      & ~ .control-input__border {
        height: 120px;
      }
    }

    &__border {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      height: 48px;
      border-radius: $radius;
      background: #EBEBEB;

      &:before {
        @include inputBorder();
      }
    }
  }
  &-error {
    font-size: 14px;
    color: $color-wrong;

    svg {
      margin-right: 8px;
      display: inline-block;
      vertical-align: top;
    }
  }
}
